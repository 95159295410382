<template>
  <div>
    <div class="card mb-1 timesheet-header">
      <div class="card-body p-1">
        <div class="d-flex justify-content-between align-items-center">
          <div class="card-header-edit">
            <p class="title title-2">
              Approvals
            </p>
          </div>
          <button
            class="back-button btn btn-secondary mx-2"
            @click="back()"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              width="18"
              height="18"
            />
            <span>Back</span>
          </button>
        </div>
      </div>
    </div>
    <!-- dismissible Alert -->
    <b-alert
      v-if="rejected_reason && (status == 'rejected' || status == 'in_process')"
      v-height-fade.appear
      variant="danger"
      fade
      dismissible
      :show="showDismissibleAlert"
      class="mb-1"
      @dismissed="showDismissibleAlert = false"
    >
      <div class="alert-body">
        <span v-if="approvaled_by">
          Rejection reason by {{ approvaled_by }} :
        </span>
        <span>
          {{ rejected_reason }}
        </span>
      </div>
    </b-alert>
    <div class="emp-approval teamUserinfo">
      <div class="d-flex align-items-center justify-content-between mb-75">
        <p class="table-title-text">
          User Details
        </p>

      </div>

      <div class="d-flex ">
        <div class="d-flex align-items-center">

          <b-avatar
            v-b-tooltip.hover
            class="avtar-text"
            :src="user ? user.picture : ''"
            :text="user ? user.full_name : ''"
            :variant="randomVariant(user)"
          />
          <div class="emp-detail">
            <p class="emp-name">
              {{ user ? user.full_name : "-" }}
            </p>
            <p class="emp-name text-lowercase">
              {{ user && user.email }} | {{ user && user.phone }}
            </p>
          </div>
        </div>

      </div>
      <div
        class="emp-week-total d-flex align-items-center justify-content-between"
      >
        <div class="d-flex">
          <p>
            <span class="week">Week:</span>{{ startDate | dayWeekformat }} -
            {{ endDate | dayWeekformat }}
          </p>
          <div class="emp-week-total mt-0 ml-5">
            <p><span class="week">total:</span>{{ totalTime }}</p>
          </div>
        </div>

        <div v-if="!loader">

          <button
            v-if="status == 'approved' || status == 'rejected'"
            :class="status == 'approved' ? 'approve-btn-2 cursorDefault' :'reject-btn-2 cursorDefault'"
          >
            {{ status == 'approved' ? 'Approved': 'Rejected' }}
          </button>
          <button
            v-if="(manage_by !=reporting_to && reporting_to == userInfo.id && (status == 'pending' || status == 'in_process')) || (manage_by == reporting_to && status != 'rejected' && status != 'approved')"
            class="approve-btn"
            @click="updateStatusConfirmation('approved')"
          >
            Approve Timesheet
          </button>
          <button
            v-if="status != 'rejected' && ((manage_by !=reporting_to && manage_by == userInfo.id && status == 'approved') || (manage_by ==reporting_to) || (manage_by !=reporting_to && reporting_to == userInfo.id && status != 'approved'))"
            class="approve-btn reject"
            @click="updateStatusConfirmation('rejected')"
          >
            Reject Timesheet
          </button>
        </div>
      </div>
    </div>
    <ClockInOut
      v-if="user && user.id"
      :start-date="startDate"
      :end-date="endDate"
      :user-id="user.id"
      :total-tasks="totalTasks"
    />

    <div class="emp-approval-task-table">
      <p class="task-table-daterange" />

      <table-loader
        v-if="loader"
        :fields="fields"
      />
      <UpdateEmpList
        v-for="(project, index) in projectIds"
        :key="index"
        :task-list="tasks[index]"
      />
    </div>
    <emp-task-detail id="timeLogView" />
    <div>
      <!-- Reject Resons modal -->
      <div>
        <b-modal
          id="updateStatus"
          v-model="modalStatus"
          ok-only
          ok-title="Accept"
          centered
          size="sm"
          content-class="reaject-reason"
          :show-ok="false"
        >
          <template #modal-title>
            Rejection Reason
          </template>
          <validation-observer ref="rejectReasonRules">
            <b-row class="addtask-content">
              <!-- Select Title Block -->
              <b-col cols="12">
                <b-form-group
                  label-for="title"
                  class="m-1 to-do-input auto-resize-teaxtarea"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Rejected reason"
                    rules="required"
                  >
                    <textarea
                      ref="textarea"
                      v-model="rejectedReasonMsg"
                      name="Rejected reason"
                      placeholder="Reason ..."
                      rows="5"
                      @input="resizeTextarea"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
          <template #modal-footer>
            <div class="text-center w-100">
              <div>
                <b-button
                  variant="primary"
                  size="sm"
                  class="btnClose"
                  @click="validationForm()"
                >
                  Submit
                </b-button>
              </div>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAlert,
  BModal, VBModal,
} from 'bootstrap-vue'
import { eventBus } from '@/main'
import * as moment from 'moment/moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import EmpTaskDetail from './EmpTaskDetail.vue'
import UpdateEmpList from './UpdateEmpList.vue'
import ClockInOut from './clockInOut.vue'

export default {
  name: 'EmpApprovals',
  components: {
    BAlert,
    EmpTaskDetail,
    BModal,
    ValidationProvider,
    ValidationObserver,
    UpdateEmpList,
    ClockInOut,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      fields: [
        { key: 'Project', thClass: 'project-class', label: 'project' },
        { key: 'date', label: 'date', tdClass: 'emp-data' },
        { key: 'duration', label: 'duration', tdClass: 'emp-data duration' },
        {
          key: 'action',
          label: 'Actions',
          thStyle: { width: '180px' },
          tdStyle: { width: '180px' },
        },
      ],

      collapseIcon: false,
      loader: true,
      timeSheetData: [],
      opened: [],
      user: null,
      startDate: null,
      endDate: null,
      manage_by: null,
      reporting_to: null,
      indexShow: null,
      totalTimeDisplay: null,
      status: null,
      rejectedReasonMsg: null,
      currentStatus: null,
      modalStatus: false,
      projectIds: [],
      tasks: [],
      totalTasks: [],
      showDismissibleAlert: true,
      rejected_reason: null,
      approvaled_by: null,
    }
  },
  computed: {
    totalTime() {
      let total = null
      Object.values(this.timeSheetData).forEach(tasks => {
        tasks.forEach((task, key) => {
          if (!key == 0) {
            task.forEach(element => {
              if (element && element.count_minutes) {
                const startTime = moment(
                  `${element.date} ${element.start_time}`,
                ).format('YYYY-MM-DD HH:mm:ss')
                // count time
                const endDate = moment(`${element.date} ${element.end_time}`) // todays date
                const duration = moment.duration(endDate.diff(startTime))
                const time = duration.asSeconds()
                total += time
              }
            })
          }
        })

        return total ? this.secondsToTime(total) : '00:00'
      })

      return total ? this.secondsToTime(total) : '00:00'
    },
  },
  watch: {
    modalStatus(nv) {
      if (!nv) {
        this.rejectedReasonMsg = null
      }
    },
  },

  created() {
    if (this.$route.params.id) {
      this.getDetail(this.$route.params.id)
    } else {
      this.$router.push('/approval')
    }
  },
  destroyed() {
    eventBus.$off('user-id')
  },
  methods: {

    toggleTd(id) {
      //
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened = []
        this.opened.push(id)
      }
    },

    async getDetail(id) {
      this.loader = true
      const response = await this.getHTTPPostResponse(
        `admin/master/timesheet/view/${id}`,
        {},
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.timeSheetData = Object.entries(data.tasks)

        this.projectIds = Object.keys(data.tasks)
        this.tasks = Object.values(data.tasks)

        let taskLog = []
        this.tasks.forEach(element => {
          taskLog = [...taskLog, ...element]
        })
        this.totalTasks = taskLog
        if (data.timesheet) {
          this.rejected_reason = data.timesheet.rejection_reason
            ? data.timesheet.rejection_reason
            : null
          this.startDate = data.timesheet.start_date
          this.endDate = data.timesheet.end_date
          this.status = data.timesheet.approval_status
          this.user = data.timesheet.user ? data.timesheet.user : null
          this.manage_by = data.timesheet.manage_by
          this.reporting_to = data.timesheet.reporting_to
          this.currentStatus = data.timesheet.approval_status
            ? data.timesheet.approval_status
            : null
          this.approvaled_by = data.timesheet.approved_by && data.timesheet.approved_by.full_name ? data.timesheet.approved_by.full_name : null
        }
      }
      this.loader = false
    },

    /**
     * Update client status
     */
    async updateStatus(status, task_id = null) {
      this.loader = true
      const input = {
        task_id: task_id || null,
        type: task_id ? 'task' : 'timesheet',
        approval_status: status,
        rejection_reason: this.rejectedReasonMsg
          ? this.rejectedReasonMsg
          : null,
      }
      const response = await this.getHTTPPostResponse(
        `admin/master/timesheet/status/${this.$route.params.id}`,
        input,
        true,
      )
      if (response && response.status === 200) {
        setTimeout(() => {
          this.$router.back()
        }, 500)
      }
      this.loader = false
      this.modalStatus = false
    },

    /**
     * update Status Confirmation Message
     */
    async updateStatusConfirmation(newStatus) {
      const label = this.currentStatus
      const status = newStatus[0].toUpperCase() + newStatus.slice(1)

      if (label !== 'in_process' || newStatus === 'rejected') {
        const confirmation = await this.conformationAlert(true, 'Are you sure ?', 'Update', `Change a status as ${status}.`)
        if (confirmation.isConfirmed) {
          if (newStatus === 'rejected') {
            this.modalStatus = true
          } else {
            this.updateStatus(newStatus)
          }
        }
      } else {
        this.updateStatus(newStatus)
      }
    },
    /**
     * Add Rejected Reason
     */
    validationForm() {
      this.$refs.rejectReasonRules.validate().then(success => {
        if (success) {
          this.updateStatus('rejected')
        }
      })
    },

    /** Back */
    back() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/component-css/approvals.css";
#updateStatus___BV_modal_content_ {
  padding: 20px;
}

.back-button {
  background-color: #3479fb !important;
  padding: 5px;
  // text-transform: capitalize;
  color: #ffffff;
  // font-size: 16px;
  // font-weight: 500;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #3479fb !important;
    color: #ffffff;
  }
}
.rejected-reason-show{
  font-size: 16px;
  font-weight: 600;
  color: red;
}
.title-2 {
  font-size: 20px;
  font-weight: 600;
}
</style>
